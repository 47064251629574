import { useEffect } from 'react';
import { useCarleadFieldChangedTracking } from '@wkda/carlead-creation-components';
const usePopupVisibilityTracking = (showPopup, hash, popupType) => {
  const trackLeadFieldChanged = useCarleadFieldChangedTracking();
  useEffect(() => {
    if (showPopup) {
      trackLeadFieldChanged('returningCustomerPopup', 'user', {
        hash,
        event: 'Visible',
        popupType: popupType
      });
      window.dataLayer.push({
        event: 'event.visible.lead.returningCustomerPopup'
      });
    }
  }, [showPopup, hash]);
};
export default usePopupVisibilityTracking;