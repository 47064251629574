import { useState, useEffect } from 'react';
const useManufacturer = data => {
  var _data$car;
  const [manufacturer, setManufacturer] = useState((data === null || data === void 0 || (_data$car = data.car) === null || _data$car === void 0 ? void 0 : _data$car.manufacturer) || '');
  useEffect(() => {
    var _data$car2;
    if (data !== null && data !== void 0 && (_data$car2 = data.car) !== null && _data$car2 !== void 0 && _data$car2.manufacturer) {
      setManufacturer(data.car.manufacturer);
    }
  }, [data]);
  return manufacturer;
};
export default useManufacturer;