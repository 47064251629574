export function useCreateEmailConsentProps() {
  return {
    createSteps: [function emailConsentSteps(_ref) {
      let [step1] = _ref;
      return [step1.concat(['EMAIL_EVALUATION_CONSENT'])];
    }],
    questionnaireMappers: [function mapEmailConsent(_ref2) {
      let {
        values
      } = _ref2;
      return {
        EMAIL_EVALUATION_CONSENT: String(values.EMAIL_EVALUATION_CONSENT).toUpperCase()
      };
    }],
    createCustomValidators: [function emailConsentValidator(_ref3) {
      let {
        validators
      } = _ref3;
      return {
        ...validators,
        EMAIL_EVALUATION_CONSENT: _ref4 => {
          let {
            value,
            errorMessages
          } = _ref4;
          if (value !== true) {
            return errorMessages.EMAIL_EVALUATION_CONSENT;
          }
          return true;
        }
      };
    }]
  };
}