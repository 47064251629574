import { useEffect, useState } from 'react';
import { useAppConfiguration, useCookie, useGetConsumerCarlead } from '@wkda/funnel-components';
const useCarLeadData = () => {
  const [hash, setHash] = useState('');
  const [popupType, setPopupType] = useState(null);
  const {
    cookies
  } = useCookie();
  const wkdaLead = cookies === null || cookies === void 0 ? void 0 : cookies.wkdaLead;
  const {
    data
  } = useGetConsumerCarlead(wkdaLead);
  // @ts-ignore
  const {
    returningCustomerPopup = []
  } = useAppConfiguration();
  useEffect(() => {
    if (wkdaLead && isValidHash(wkdaLead) && data) {
      setHash(wkdaLead);

      // show popup based on conditions
      const popup = getPopupType(data, returningCustomerPopup);
      setPopupType(popup);
    }
  }, [wkdaLead, data, returningCustomerPopup]);
  const isValidHash = hash => /^[0-9a-f]{32}$/.test(hash);
  const getPopupType = (data, allowedPopups) => {
    // lead popup
    const hasValidLeadStatus = data.statusId === 11 || data.statusId === 0;
    const hasSelfEvaluationProperty = data.carProperties.some(property => property.propertyId === 6410 && property.value === 'online-self-evaluation');
    if (allowedPopups.includes('lead') && hasValidLeadStatus && !hasSelfEvaluationProperty) {
      return 'lead';
    }

    // booking popup
    if (allowedPopups.includes('booking') && data.statusId === 15) {
      const validHandover = data.priceExpiresInDays > 0;
      return validHandover ? 'booking.valid' : 'booking.invalid';
    }
    return null;
  };
  return {
    hash,
    data,
    popupType
  };
};
export default useCarLeadData;