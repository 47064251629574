import { useEffect } from 'react';
const useScrollToTopOnReload = () => {
  useEffect(() => {
    if (sessionStorage.getItem('scrollToTop')) {
      // Disable browser's automatic scroll restoration
      window.history.scrollRestoration = 'manual';
      // Scroll to top
      window.scrollTo(0, 0);
      // Remove the flag from sessionStorage
      sessionStorage.removeItem('scrollToTop');
    }
  }, []);
};
export default useScrollToTopOnReload;