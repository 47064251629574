import { useState } from 'react';
import useCarLeadData from './useCarLeadData';
import usePopupVisibility from './usePopupVisibility';
import usePopupVisibilityTracking from './usePopupVisibilityTracking';
import useManufacturer from './useManufacturer';
import { useAppConfiguration, useCookie } from '@wkda/funnel-components';
import { useCarleadFieldChangedTracking } from '@wkda/carlead-creation-components';
import { getReturningCustomerPopupContent } from './returningCustomerPopupContent';
import { scroll } from '../../../helpers';
const useReturningCustomerPopup = () => {
  const {
    fullLocale
  } = useAppConfiguration();
  const {
    hash,
    data,
    popupType
  } = useCarLeadData();
  const manufacturer = useManufacturer(data);
  const {
    showPopup,
    setShowPopup,
    showCustomerBanner
  } = usePopupVisibility(hash, popupType);
  const [disabledButton, setDisabledButton] = useState(null);
  const trackLeadFieldChanged = useCarleadFieldChangedTracking();
  const {
    removeCookie
  } = useCookie();
  usePopupVisibilityTracking(showPopup, hash, popupType);
  const handleButtonClick = async buttonType => {
    setDisabledButton(buttonType);
    trackLeadFieldChanged('returningCustomerPopup', 'user', {
      hash,
      click: buttonType,
      popupType: popupType
    });
    if (buttonType === 'restart') {
      await handleRestart();
    } else {
      await handleResume(buttonType);
    }
  };
  const handleRestart = async () => {
    // clear step1 fields
    clearLocalStorage();
    // clear wkdaLead cookie
    clearCookies();
    // store a flag in sessionStorage to indicate restart
    sessionStorage.setItem('scrollToTop', 'true');
    // delay reload by 1 second to ensure tracking is sent.
    await delay(1000);

    // if the url contains step-2 bring back the user to the previous page (aka step1, otherwise they'll land on broken step2)
    if (window.location.pathname.includes('/step-2/')) {
      window.history.back();
    } else {
      window.location.reload();
    }
  };
  const handleResume = async buttonType => {
    const buttonConfig = content.buttons.find(button => button.action === buttonType);
    if (!buttonConfig || !buttonConfig.resumeUrl) {
      return;
    }
    const resumeUrl = "/" + buttonConfig.resumeUrl + hash;
    await delay(1000);
    window.location.href = resumeUrl;
  };
  const clearLocalStorage = () => {
    localStorage.removeItem("dsb-core_" + fullLocale + "_v1");
    localStorage.removeItem("dsb-core-flexible-questionnaire_" + fullLocale + "_v1");
  };
  const clearCookies = () => {
    const domain = getDomain();
    const cookieName = 'wkdaLead';
    removeCookie(cookieName, {
      path: '/',
      domain
    });
  };
  const getDomain = () => {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) return 'localhost';
    if (hostname.includes('auto1-test.com')) return '.auto1-test.com';
    const domainMapping = {
      'nl-NL': '.wijkopenautos.nl',
      'de-DE': '.wirkaufendeinauto.de',
      'it-IT': '.noicompriamoauto.it',
      'es-ES': '.compramostucoche.es',
      'fr-FR': '.vendezvotrevoiture.fr',
      'sv-SE': '.vikoperdinbil.se',
      'de-AT': '.wirkaufendeinauto.at',
      'nl-BE': '.wijkopenautos.be',
      'fr-BE': '.vendezvotrevoiture.be'
    };
    return domainMapping[fullLocale] || '.wirkaufendeinauto.de';
  };
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  const handleClosePopup = () => {
    trackLeadFieldChanged('returningCustomerPopup', 'user', {
      hash,
      click: 'Close',
      popupType: popupType
    });
    setShowPopup(false);
    showCustomerBanner();
  };

  // getting the nested content for the popup based on the popupType ('lead' or 'booking.valid').
  const content = popupType ? popupType.split('.').reduce((acc, key) => acc === null || acc === void 0 ? void 0 : acc[key], getReturningCustomerPopupContent) : null;
  return {
    hash,
    showPopup,
    setShowPopup,
    showCustomerBanner,
    handleButtonClick,
    disabledButton,
    handleClosePopup,
    manufacturer,
    content
  };
};
export default useReturningCustomerPopup;