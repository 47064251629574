import { useEffect, useState } from 'react';
const usePopupVisibility = (hash, popupType) => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (hash && popupType) {
      setShowPopup(true);
      hideCustomerBanner();
    } else {
      setShowPopup(false);
      showCustomerBanner();
    }
  }, [hash, popupType]);
  const setCustomerBannerVisibility = isVisible => {
    const element = document.querySelector('.banner-return-customer');
    if (!element) return;
    element.style.display = isVisible ? 'block' : 'none';
  };
  const hideCustomerBanner = () => setCustomerBannerVisibility(false);
  const showCustomerBanner = () => setCustomerBannerVisibility(true);
  return {
    showPopup,
    setShowPopup,
    showCustomerBanner
  };
};
export default usePopupVisibility;