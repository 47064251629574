import { getEnvironment } from '../../../helpers';
export const getReturningCustomerPopupContent = {
  lead: {
    welcome: {
      id: 'carlead.returningCustomerPopup.lead.welcome',
      message: 'Welcome Back!'
    },
    title: {
      id: 'carlead.returningCustomerPopup.lead.title',
      message: 'Ready to Receive Your Final Sale Price?'
    },
    body: {
      id: 'carlead.returningCustomerPopup.lead.body',
      message: '<0>Save time</0> by continuing where you left off with your {manufacturer}.'
    },
    buttons: [{
      id: 'carlead.returningCustomerPopup.lead.button.restart',
      message: 'Start New',
      action: 'restart',
      trackingLabel: 'Start New',
      class: 'returning-customer-popup__button-start btn btn-lg',
      loaderClass: 'returning-customer-popup__loader--blue'
    }, {
      id: 'carlead.returningCustomerPopup.lead.button.resume',
      message: 'Resume',
      action: 'resume',
      trackingLabel: 'Resume',
      resumeUrl: 'inspection/',
      class: 'returning-customer-popup__button-resume btn btn-secondary btn-lg'
    }]
  },
  booking: {
    valid: {
      welcome: {
        id: 'carlead.returningCustomerPopup.booking.valid.welcome',
        message: 'Welcome Back!'
      },
      title: {
        id: 'carlead.returningCustomerPopup.booking.valid.title',
        message: 'Ready to get the money for your car?'
      },
      body: {
        id: 'carlead.returningCustomerPopup.booking.valid.body',
        message: 'Your current <0>sales price</0> is ready. Book your appointment now to sell your {manufacturer}.'
      },
      buttons: [{
        id: 'carlead.returningCustomerPopup.booking.valid.button.proceed',
        message: 'Make appointment',
        action: 'valid-booking',
        resumeUrl: 'booking/',
        trackingLabel: 'Valid Booking',
        class: 'returning-customer-popup__button-proceed btn btn-secondary btn-lg'
      }]
    },
    invalid: {
      welcome: {
        id: 'carlead.returningCustomerPopup.booking.invalid.welcome',
        message: 'Welcome Back!'
      },
      title: {
        id: 'carlead.returningCustomerPopup.booking.invalid.title',
        message: 'Ready to get the money for your car?'
      },
      body: {
        id: 'carlead.returningCustomerPopup.booking.invalid.body',
        message: 'Your current <0>sales price</0> is ready, but prices can change over time. Book your appointment now to sell your {manufacturer}.'
      },
      buttons: [{
        id: 'carlead.returningCustomerPopup.booking.invalid.button.reeva',
        message: 'Get your updated price',
        action: 'reeva',
        resumeUrl: "" + getEnvironment('self-inspection/8-0/'),
        trackingLabel: 'Reeva',
        class: 'returning-customer-popup__button-start btn btn-lg',
        loaderClass: 'returning-customer-popup__loader--blue'
      }, {
        id: 'carlead.returningCustomerPopup.booking.invalid.button.proceed',
        message: 'Book appointment',
        action: 'invalid-booking',
        resumeUrl: 'booking/',
        trackingLabel: 'Invalid Booking',
        class: 'returning-customer-popup__button-proceed btn btn-secondary btn-lg'
      }]
    }
  }
};