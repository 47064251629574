export const SLIDER_SETTINGS = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '20px',
  slidesToShow: 1,
  speed: 1000,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  slideSpacing: 20,
  variableWidth: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        vertical: false,
        slidesToShow: 1,
      },
    },
  ],
};
