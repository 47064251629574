import React from 'react';
import { carleadCreationGetInitialProps } from '@wkda/carlead-creation-components';
import {
  BaseLeadPage,
  CarleadStepCompoundTradeinConsentCustomerPopup,
  CarleadTopBlueCarHCSliderMap,
} from '@wkda/marketing-components';
import { SLIDER_SETTINGS } from "../../../default/slider";

function CarleadCreation() {
  return (
    <BaseLeadPage
      form={CarleadStepCompoundTradeinConsentCustomerPopup}
      content={CarleadTopBlueCarHCSliderMap}
      happyCustomerSettingsOverrides={SLIDER_SETTINGS}
      className='mobile-blue-bg'
      showCounterHeadline={false}
      howItWorksShowCta={true}
    />
  );
}

CarleadCreation.getInitialProps = carleadCreationGetInitialProps;

export default CarleadCreation;
